import store from '../store';
import genericRequest from './genericRequest';

const materialGenerico = {
  async filterMateriaisGenericosById(id) {
    const { state: { materiaisGenericos } } = store;
    if (!materiaisGenericos) {
      const result = await genericRequest.get({}, 'materialGenerico/allByTipo');
      store.commit('setMateriaisGenericosByTipo', result);
      const matGenericos = store.state.materiaisGenericos;
      return matGenericos.filter((el) => el.id_material_generico === id)[0];
    }
    return materiaisGenericos.filter((el) => el.id_material_generico === id)[0];
  },
};

export default materialGenerico;
